import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// import { useWindowSize } from "react-use";
import { InfiniteGrids } from 'infinite-grids';
// import { Masonry } from 'react-masonry/dist';
import Masonry from 'react-masonry-infinite';

import { isObjEmpty } from 'utils/util';
import { getPortfolioPhotoList, insertHitItem } from './store/action';

// import PhotoViewerModal from './PhotoViewerModal';
import PhotoFullViewerModal from './PhotoFullViewerModal';

import NoImage from 'resources/common/no_image_photo.png';

const Photo = ({ portfolioInfo, searchPortfolioNo, callCount, setConnectCallCount }) => {
  const dispatch = useDispatch();

  const [portfolioList, setPortfolioList] = useState([]);
  const [searchPtfoSeqno, setSearchPtfoSeqno] = useState();

  // Viewer 모달 팝업
  const [viewerModal, setViewerModal] = useState(false);
  const [currentPhotoInfo, setCurrentPhotoInfo] = useState();
  // const [hasMore, setHasMore] = useState(true);

  // Viewer 모달 팝업 
  const handleViewerModal = () => {
    setViewerModal(!viewerModal);
  }

  const width = window.innerWidth;

  // 포트폴리오 클릭
  const clickItem = (item) => {
    // 로그
    dispatch(insertHitItem(item.ptfoSeqno));

    // 포트폴리오 사진 목록 호출
    dispatch(getPortfolioPhotoList(item.ptfoSeqno));

    // 모달 호출 시 하단 주석 해제
    setCurrentPhotoInfo(item);
    handleViewerModal();
  }

  /**
   * 포트폴리오 목록을 부모로 부터 가져와 내부 변수에 저장
   * 포트폴리오 목록과 URL로 넘어온 포트폴리오번호가 모두 존재한다면 바로 포트폴리오 팝업창 호출
   */
  useEffect(() => {
    if (portfolioInfo) {
      const portfolioItems = [];
      portfolioInfo.map(item => {
        const imgSrc = `${process.env.REACT_APP_AWS_S3_URL}/${item.atchFilePath}/${item.atchFileSaveNm}`;
        portfolioItems.push({
          ...item,
          imageSrc: imgSrc
        })
      })
      // console.log("portfolioItems : ", portfolioItems)
      setPortfolioList(portfolioItems);
    }
    if (searchPortfolioNo !== null && searchPortfolioNo !== '') {
      setSearchPtfoSeqno(searchPortfolioNo)
    }
  }, [portfolioInfo, searchPortfolioNo]);

  useEffect(() => {
    if (!isObjEmpty(portfolioList) && searchPtfoSeqno !== null && searchPtfoSeqno !== '') {
      // 포트폴리오 목록과 URL로 넘어온 포트폴리오번호가 모두 존재한다면 바로 포트폴리오 팝업창 호출
      if (!isObjEmpty(portfolioList)) {
        const findItem = portfolioList.filter(item => item.ptfoSeqno === searchPtfoSeqno)
        if (!isObjEmpty(findItem)) {
          if (callCount < 1) {
            setTimeout(() => {
              clickItem(findItem[0])
              setConnectCallCount(1)
            }, 200)
          }
        }
      }
    }
  }, [portfolioList, searchPtfoSeqno]);

  const loadMore = async () => {
    
  };

  // useEffect(() => {
  //   console.log('portfolioInfo ', portfolioInfo)
  // }, [portfolioInfo])

  // const numberOfColumns = width && width < 420 ? 1 : 2;
  const numberOfGap = width && width < 720 ? 60 : 160;

  return (
    <>
    <ContentContainer >
      <ContentWrapper key='web'>
        <InfiniteGrids
          items={portfolioList}
          loadMore={loadMore}
          horizontalGap={164}
          verticalGap={160}
          renderItem={(item, index) => (
            <PhotoItem key={index} onClick={() => clickItem(item)}>
              <div key={index}>
                <img
                  src={item.imageSrc}
                  alt=""
                  style={{
                    width: "544px",
                    // width: {valueOfWidth},
                    // height: "100%",
                    borderRadius: 0,
                    overflow: "hidden",
                  }}
                  key={index}
                />
              </div>
              <DescWrapper key={'info_'.concat(index)}>
                <div className='title' key={'title'.concat(index)}>{item.ptfoNm}</div>
                <div className='clnt' key={'clnt'.concat(index)}>{item.clntNm}</div>
              </DescWrapper>
            </PhotoItem>
          )}
          loader={<h4>Loading...</h4>}
          numberOfColumns={2}
          style={{ overflow: "hidden" }}
        />
        {/* <Masonry
          hasMore={false}
          loadMore={loadMore}
          // className="masonry"
          style={{maxWidth:'1090px', gap:'160px'}}
        >
          {portfolioList.map((item, index) => {
            return (
            <PhotoItem key={index} onClick={() => clickItem(item)}>
              <div key={index}>
                <img
                  src={item.imageSrc}
                  alt=""
                  style={{
                    width: "544px",
                    height: "100%",
                    borderRadius: 0,
                    overflow: "hidden",
                  }}
                  key={index}
                />
              </div>
              <DescWrapper key={'info_'.concat(index)}>
                <div className='title' key={'title'.concat(index)}>{item.ptfoNm}</div>
                <div className='clnt' key={'clnt'.concat(index)}>{item.clntNm}</div>
              </DescWrapper>
            </PhotoItem>
            )
          })}
        </Masonry> */}
      </ContentWrapper>
      <MobileContentWrapper key='mobileWeb'>
        <InfiniteGrids
          items={portfolioList}
          loadMore={loadMore}
          horizontalGap={164}
          verticalGap={0}
          renderItem={(item, index) => (
            <PhotoItem key={index} onClick={() => clickItem(item)}>
              <div key={index}>
                <img
                  src={item.imageSrc}
                  alt=""
                  style={{
                    width: "91vw",
                    // width: {valueOfWidth},
                    // height: "100%",
                    borderRadius: 0,
                    overflow: "hidden",
                  }}
                  key={index}
                />
              </div>
              <DescWrapper key={'info_'.concat(index)}>
                <div className='title' key={'title'.concat(index)}>{item.ptfoNm}</div>
                <div className='clnt' key={'clnt'.concat(index)}>{item.clntNm}</div>
              </DescWrapper>
            </PhotoItem>
          )}
          loader={<h4>Loading...</h4>}
          numberOfColumns={1}
          style={{ overflow: "hidden" }}
        />
      </MobileContentWrapper>
    </ContentContainer>
    <PhotoFullViewerModal 
      open={viewerModal}
      handleModal={handleViewerModal}
      photoInfo={currentPhotoInfo}
    />
    </>
  )
}

export default Photo;

const ContentContainer = styled.div`
  // display: flex;
  // flex-flow: row wrap;
  // gap: 160px 164px;
  margin-bottom: 80px;
  // width: 1260px;
  overflow: hidden;
  
  @media ${({ theme }) => theme.device.mobile} {

  }
`

const ContentWrapper = styled.div`
  display: flex;
  // width: 1260px;
  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`

const MobileContentWrapper = styled.div`
  display: none;
  @media ${({ theme }) => theme.device.mobile} {
    display: flex;
    flex-direction: column;
    // gap: 30px;
  }
`

const PhotoItem = styled.div`
  width: 544px;
  cursor: pointer;

  @media ${({ theme }) => theme.device.mobile} {
    width: 91vw;
    cursor: pointer;
  }
`

const Item = styled.div`

  @media ${({ theme }) => theme.device.mobile} {
    width: 91vw;
    cursor: pointer;
  
  }
`

const DescWrapper = styled.div`
  .title {
    font-family: Prompt;
    font-size: 20px;
    font-weight: 700;
    line-height: 30.24px;
    letter-spacing: 0.2em;
    text-align: left;
    color: #FFFFFF;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .clnt {
    color: #FFFFFF;
    font-family: Prompt;
    font-size: 14px;
    font-weight: 700;
    line-height: 21.17px;
    letter-spacing: 0.2em;
    text-align: left;
    // margin-bottom: 80px;
  }

  @media ${({theme}) => theme.device.mobile} {
    display: block;

    .title {
      // font-weight: 400;
      // font-size: 16px;
      // line-height: 19px;
      font-family: Prompt;
      font-size: 20px;
      font-weight: 700;
      line-height: 30.24px;
      letter-spacing: 0.2em;
      text-align: left;
      color: #FFFFFF;
      word-break: keep-all;
  
      margin-top: 24px;
      // margin-bottom: 10px;
    }

    .clnt {
      font-family: Prompt;
      font-size: 14px;
      font-weight: 700;
      line-height: 21.17px;
      letter-spacing: 0.2em;
      text-align: left;
      margin-bottom: 60px;
    }
  };
`

const ThumbNailWrapper = styled.div`
  position: relative;
  text-align: center;
  text-align: -webkit-center;
`

const ThumbnailMobile = styled.div`
  display: none;
  
  @media ${({theme}) => theme.device.mobile} {
    display: block;
    width: 100%;
    height: 100%;
    // aspect-ratio: auto;
    background: url(${props => props.image}), url(${NoImage}) no-repeat 50% 50%;
    // background-size: cover, contain;
  };
`

const PhotoInfoWrapper = styled.div``
